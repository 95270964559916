.modal-btn{
    width: 250px;
    height: 50px;
    font-size: 18px;
    background-image: linear-gradient(to right top, rgb(255, 208, 0), rgb(174, 0, 255));
    color: white;
    border: 1px solid rgb(132, 0, 255);
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transition: all 0.3s ease;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    text-transform: uppercase;
    margin-top: 2rem;
}

.modal-btn:hover {
    background-color: rgb(0, 225, 255);
    color: black;
    border: 1px solid transparent;
    transition: all 0.3s ease;
}

.modal{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;

}

.modal-butterfly img{
    position: fixed;
    top: 60px;
    right: 90px;
    width: 400px;
    height: 400px;
}

.modal-pysduck img{
    position: fixed;
    bottom: 10px;
    left: 80px;
    width: 400px;
    height: 400px;
}

.modal-score{
    font-size: 40px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
}

.modal-results{
    font-size: 25px;
    color: black;
    margin-bottom: 10px;
}

.modal-title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 70px;
    margin-bottom: 10px;
    color: black;
}

.modal-answers ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 25px;
}

.modal-answers {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 25px;
}

.modal-butterfly img{
    position: fixed;
    top: 60px;
    right: 90px;
    width: 400px;
    height: 400px;
}

.modal-pysduck img{
    position: fixed;
    bottom: 10px;
    left: 80px;
    width: 400px;
    height: 400px;
}