.QuestionCard {
    max-width: 700px;
    width: 100%;
    height: 560px;
    background-color: rgb(246, 180, 180); 
    box-shadow: 20px 20px 50px 10px rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: 1px solid transparent;
    border-radius: 10px;

}

.questionCard-title{

    font-size: 24px;
    font-weight: bold;
    color: black;
    margin: 0 0 1rem 0;
    text-align: center;


}

.QuestionCard button {
    width: 48%;
    height: 40px;
    margin: .3rem;
    height: 50px;
    background-image: linear-gradient(to right top, rgb(255, 208, 0), rgb(174, 0, 255));
    color: white;
    border: 1px solid rgb(162, 0, 255);
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transition: all 0.3s ease;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    text-transform: uppercase;
    margin-top: 0.2rem;
}

.QuestionCard button:hover {
    background-color: rgb(0, 225, 255);
    color: black;
    border: 1px solid transparent;
    transition: all 0.3s ease;
}


.QuestionCard-timer{
    font-family: orbitron;
    width: 280px;
    height: 50px;
    font-size: 25px;
    background-image: linear-gradient(to right top, rgb(255, 208, 0), rgb(174, 0, 255));
    color: rgb(255, 255, 255);
    margin-bottom: 2rem;
    border-radius: 7%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;


}

.questionCard-image img {
    display: block;
    margin: 0 auto;
    max-width: 400px;
    max-height: 200px;
    margin-bottom: 0.5rem;
}

.QuestionCard-number{
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 0 0 1rem 0;
    text-align: center;
}