.introduce{
    width : 100%;
    height: 100vh;
    background-image: linear-gradient(to right top, rgb(219, 141, 40), rgb(89, 14, 211));
    display: flex;
    align-items: center;
    justify-content: center;

}


.introduce-container{
    max-width: 700px;
    width: 100%;
    height: 560px;
    background-color: rgb(246, 180, 180); 
    background-size: cover;
    background-position: center;
    box-shadow: 20px 20px 50px 10px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: 1px solid transparent;
    border-radius: 10px;
}

.introduce-btn{
    width: 250px;
    height: 50px;
    font-size: 18px;
    background-image: linear-gradient(to right top, rgb(219, 141, 40), rgb(89, 14, 211));    box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    cursor: pointer;

}

.introduce-btn:hover{
    background-color: rgb(0, 225, 255);
    color: black;
    border: 1px solid transparent;
    transition: all 0.3s ease;
}

.quiz-info{
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    color: rgb(34, 32, 32);
}